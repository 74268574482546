import * as React from "react";
import {
  apiPlugin,
  storyblokInit,
  useStoryblokState,
} from "gatsby-source-storyblok";

import { graphql } from "gatsby";
import Layout from "../components/layout";

storyblokInit({
  accessToken: process.env.GATSBY_SB_PREVIEW_TOKEN,
  use: [apiPlugin],
});

type NotFoundPageProps = {
  data: any;
  location: Location;
};

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const story = useStoryblokState(data.storyblokEntry);

  return <Layout content={story.content} />;

};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    storyblokEntry(full_slug: { eq: "page_404" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`;
